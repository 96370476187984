import React from 'react';
import { SiKnowledgebase } from 'react-icons/si';
import { BsTools, BsHeart, BsBag } from 'react-icons/bs';
import HomeFiveSecondSingleService from './../../../components/HomeFiveSecondSingleService/HomeFiveSecondSingleService';


const HomeFiveSecondServices = () => {
   return (
      <>
         <section className="services__area-3 pt-115 pb-160">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6">
                     <div className="section-title section__title-3 mb-70">
                        <h2>We Help <br /> Clients Make Profit</h2>
                        <p>We help you weather today's uncertainty through our best team.</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                        <ul className="nav nav-pills " id="services-tab" role="tablist">
                           <li className="nav-item mb-45">
                              <a className="nav-link active" id="share-tab" data-bs-toggle="pill" href="#share" role="tab" aria-controls="share" aria-selected="true">
                                 <i> <SiKnowledgebase /> </i>Predictive Analytics
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="customer-tab" data-bs-toggle="pill" href="#deployment" role="tab" aria-controls="deployment" aria-selected="true">
                                 <i ><BsTools /></i> Customer Insights
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="social-tab" data-bs-toggle="pill" href="#custom" role="tab" aria-controls="custom" aria-selected="true">
                                 <i ><BsHeart /></i> Business Intelligence
                              </a>
                           </li>
                           <li className="nav-item mb-30">
                              <a className="nav-link" id="financial-tab" data-bs-toggle="pill" href="#customer" role="tab" aria-controls="customer" aria-selected="true">
                                 <i ><BsBag /></i> Process Optimization
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="services__tab grey-bg-18">
                        <div className="tab-content" id="services-content">
                           <div className="tab-pane fade show active" id="share" role="tabpanel" aria-labelledby="share-tab">
                              <HomeFiveSecondSingleService title="Forecast trends to make smarter decisions and boost profits." />
                           </div>
                           <div className="tab-pane fade" id="deployment" role="tabpanel" aria-labelledby="deployment-tab">
                              <HomeFiveSecondSingleService title="Understand your audience to improve engagement and sales." />
                           </div>
                           <div className="tab-pane fade" id="custom" role="tabpanel" aria-labelledby="custom-tab">
                              <HomeFiveSecondSingleService title="Turn data into actionable insights with custom dashboards." />
                           </div>
                           <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                              <HomeFiveSecondSingleService title="Streamline operations to cut costs and increase margins." />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFiveSecondServices;