import React from 'react';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaFacebookF, FaLinkedin, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const TeamDetailsArea = () => {
   return (
      <>
         <section className="team__details pt-120 pb-160">
            <div className="container">
               <div className="team__details-inner p-relative white-bg">
                  <div className="team__details-shape p-absolute">
                     <img src="assets/img/icon/team/shape-1.png" alt="" />
                  </div>
                  <div className="row">
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-img w-img mr-70">
                           <img src="assets/img/team/details/team-01.jpg" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-content pt-105">
                           <span>Vice President</span>
                           <h3>Jacob Jayraj</h3>
                           <p>The Vice President of Risk Management leads the team responsible for identifying, assessing, and mitigating risks to safeguard the organization's assets, reputation, and operations.</p>
                           <div className="team__details-contact mb-45">
                              <ul>
                                 <li>
                                    <div className="icon theme-color ">
                                       <i > <FaEnvelope /> </i>
                                    </div>
                                    <div className="text theme-color ">
                                       <span><a href="mailto:info@jfdevops.com">info@jfdevops.com</a></span>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="icon theme-color">
                                       <i ><FaPhoneAlt /> </i>
                                    </div>
                                    <div className="text theme-color">
                                       <span><a href="tel:(+91)-776-028-8526">(+91) 77602 88526</a></span>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="icon">
                                       <i > <BiMap /> </i>
                                    </div>
                                    <div className="text">
                                       <span>RJ Garden, 1st Floor, Marathahalli,
                                          Bengaluru, Karnataka 560037.</span>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div className="team__details-social theme-social" >
                              <ul>
                                 <li>
                                    <a href="https://wa.me/+917760288526">
                                       <i ><FaWhatsapp /> </i>
                                       <i ><FaWhatsapp /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.linkedin.com/company/jf-devops/?viewAsMember=true">
                                       <i ><FaLinkedin /> </i>
                                       <i ><FaLinkedin /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://wa.me/+917760288526">
                                       <i ><FaFacebookF /></i>
                                       <i ><FaFacebookF /></i>
                                    </a>

                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="team__details-info mt-60">
                        <h4 >Information</h4>
                        <p >The Vice President of Risk Management leads the team focused on identifying, assessing, and mitigating risks to protect the organization. Responsibilities include developing risk management strategies, overseeing risk assessments, designing mitigation policies, managing incident responses, and ensuring regulatory compliance. The team structure includes Risk Analysts for detailed assessments, Compliance Officers for regulatory adherence, Incident Managers for response coordination, and Risk Consultants for expert advice. The VP ensures a proactive risk management culture, safeguarding assets and enhancing overall organizational resilience.</p>
                        <p >"In risk management, our goal is not just to react to threats, but to anticipate them, mitigate their impact, and turn potential challenges into opportunities for growth. Through strategic foresight and diligent planning, we protect our organization and drive its success."</p>

                        <Link to="/contact" className="z-btn mt-10">Appointment</Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamDetailsArea;