import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import StyleHeaderSeven from './StyleHeaderSeven/StyleHeaderSeven';
import HomeSevenHeroSection from './HomeSevenHeroSection/HomeSevenHeroSection';
import HomeSevenServices from './HomeSevenServices/HomeSevenServices';
import HomeSevenSecondServices from './HomeSevenSecondServices/HomeSevenSecondServices';
import HomeSevenProjects from './HomeSevenProjects/HomeSevenProjects';
import HomeSevenFaq from './HomeSevenFaq/HomeSevenFaq';
import HomeSevenFooter from './HomeSevenFooter/HomeSevenFooter';

const HeaderStyleSeven = () => {
   return (
      <>
         <PageHelmet pageTitle="Header Style Seven" />

         <StyleHeaderSeven />
         <HomeSevenHeroSection />
         <HomeSevenServices />
         <HomeSevenSecondServices />
         <HomeSevenProjects />
         <HomeSevenFaq />
         <CommonCtaArea />
         <HomeSevenFooter />
      </>
   );
};

export default HeaderStyleSeven;