import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const ServicesDetailsArea = () => {
   return (
      <>
         <section className="services__details pt-115 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                     <div className="services__sidebar mr-50">
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>Categories</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__link">
                                 <ul>
                                    <li><a href="/servicesDetails">Branding</a></li>
                                    <li><a href="/servicesDetails">Search Engine Optimization (SEO)</a></li>
                                    <li><a href="/servicesDetails">Pay-Per-Click (PPC) Advertising</a></li>
                                    <li><a href="/servicesDetails">Video Production</a></li>
                                    <li><a href="/servicesDetails">Animated Graphics</a></li>
                                    <li><a href="/servicesDetails">Explainer Videos</a></li>
                                    <li><a href="/servicesDetails">Full-Stack Development</a></li>
                                    <li><a href="/servicesDetails">Custom Web Applications</a></li>
                                    <li><a href="/servicesDetails">Responsive Design</a></li>
                                    <li><a href="/servicesDetails">Website Maintenance</a></li>
                                    <li><a href="/servicesDetails">Data Analytics</a></li>
                                    <li><a href="/servicesDetails">Data Visualization</a></li>
                                    <li><a href="/servicesDetails">Predictive Modeling</a></li>
                                    <li><a href="/servicesDetails">Business Intelligence</a></li>
                                    <li><a href="/servicesDetails">Machine Learning Models</a></li>
                                    <li><a href="/servicesDetails">Predictive Analytics</a></li>
                                    <li><a href="/servicesDetails">Security Assessments</a></li>
                                    <li><a href="/servicesDetails">Threat Detection</a></li>
                                    <li><a href="/servicesDetails">Automation Solutions</a></li>
                                    <li><a href="/servicesDetails">Risk Management</a></li>
                                    <li><a href="/servicesDetails">Incident Response</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="services__widget grey-bg-18 mb-40">
                           <div className="services__widget-title">
                              <h4>Subscribe Now</h4>
                           </div>
                           <div className="services__widget-content">
                              <div className="services__form">
                                 <form>
                                    <input type="text" placeholder="Name" />
                                    <input type="email" placeholder="Email" />
                                    <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                 </form>
                              </div>
                           </div>
                        </div>
                        <div className="services__action grey-bg-18 mb-15">
                           <Link to="#"><i><FiDownload /></i>Download docs</Link>
                        </div>
                        <div className="services__action grey-bg-18 mb-15">
                           <Link to="#"><i><AiOutlineFilePdf /></i>Characteristics</Link>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                     <div className="services__text">
                        <h3>We give the best consulting</h3>
                        <p>Providing 24/7 support enhances accessibility and reduces frustration. Personalized interactions make customers feel valued and improve loyalty. Proactive support addresses issues before they escalate, reducing complaints and demonstrating care. Omnichannel support ensures a seamless experience across various communication methods, increasing engagement and satisfaction.</p>
                     </div>
                     <div className="services__img mb-45 w-img">
                        <img src="assets/img/services/details/services-01.jpg" alt="" />
                     </div>
                     <div className="services__text">
                        <h3>We Unlock Potential</h3>
                        <p>We unlock potential across a broad spectrum of services to elevate your business. Our expertise spans branding, where we craft distinctive identities that resonate; digital marketing, driving growth through targeted online strategies; motion media, creating engaging video and animation content; animation, bringing stories to life with dynamic visuals; full-stack web development, building robust and scalable websites; data science, converting data into actionable insights; AI/ML, leveraging advanced technologies for intelligent solutions; and cybersecurity, safeguarding your digital assets from threats. With our comprehensive approach, we ensure your business thrives in a competitive landscape.</p>
                     </div>
                     <div className="services__list mb-40">
                        <ul>
                           <li>Creating impactful identities.</li>
                           <li>Driving growth through targeted online strategies.</li>
                           <li>Creating engaging videos and dynamic visuals that bring your stories to life.</li>
                           <li>Building robust websites, deriving insights from data, leveraging AI, and ensuring digital security.</li>
                        </ul>
                     </div>
                     <div className="services__text">
                        <h4 >How Can We Help Your Business Thrive?
                        </h4>
                        <p>We offer comprehensive solutions including Branding for impactful identities, Digital Marketing for growth, Motion Media & Animation for engaging content, and Web Development, Data Science, AI/ML, & Cybersecurity for robust, data-driven, and secure digital solutions. Our integrated approach ensures your business excels in a competitive landscape.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesDetailsArea;