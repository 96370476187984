import { HashRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import HeaderStyleFive from "./pages/HomeFive/HeaderStyleFive";
import HeaderStyleFour from "./pages/HomeFour/HeaderFourFour";
import HeaderStyleSeven from "./pages/HomeSeven/HeaderStyleSeven";
import HeaderStyleSix from "./pages/HomeSix/HeaderStyleSix";
import Home from './pages/Home/Home';
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import Team from "./pages/Team/Team";
import TeamDetails from "./pages/TeamDetails/TeamDetails";

import "./App.css"


function App() {
  return (
    <>
      <HashRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/homeTwo" element={<HomeTwo />} />
          <Route path="/homeThree" element={<HomeThree />} />
          <Route path="/headerStyleFour" element={<HeaderStyleFour />} />
          <Route path="/headerStyleFive" element={<HeaderStyleFive />} />
          <Route path="/headerStyleSix" element={<HeaderStyleSix />} />
          <Route path="/headerStyleSeven" element={<HeaderStyleSeven />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/servicesDetails" element={<ServicesDetails />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolioDetails" element={<PortfolioDetails />} />
          <Route path="/team" element={<Team />} />
          <Route path="/teamDetails" element={<TeamDetails />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogDetails" element={<BlogDetails />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <div className="fixed-icons">
          <div className="left-icon">
            <i className="fas fa-arrow-up" style={{ color: 'white', fontSize: '24px' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
          </div>
          <div className="right-icon">
            <a href="https://wa.me/+917760288526" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp" style={{ color: 'white', fontSize: '24px' }} />
            </a>
          </div>
        </div>
      </HashRouter >
    </>
  );
}

export default App;
