import React from 'react';
import { Link } from 'react-router-dom';

const HomeFiveHeroSection = () => {
   return (
      <React.Fragment>
         <section className="hero__area p-relative">
            <div className="hero__shape">
               <img className="one" src="assets/img/icon/slider/03/icon-1.png" alt="" />
               <img className="two" src="assets/img/icon/slider/03/icon-2.png" alt="" />
               <img className="three" src="assets/img/icon/slider/03/icon-3.png" alt="" />
               <img className="four" src="assets/img/icon/slider/03/icon-4.png" alt="" />
               <img className="five" src="assets/img/icon/slider/03/icon-6.png" alt="" />
               <img className="six" src="assets/img/icon/slider/03/icon-7.png" alt="" />
            </div>
            <div className="hero__item hero__height d-flex align-items-center">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12 col-lg-7 d-flex align-items-center">
                        <div className="hero__content">
                           <span>Welcome To JF DevOps</span>
                           <h1>Transform Data into Actionable Insights</h1>
                           <p>Transform your engineering and manufacturing projects with data and AI. Boost efficiency, sustainability, and growth while productivity and customer relevance.</p>
                           <Link to="/about" className="z-btn z-btn-border">See what's New</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default HomeFiveHeroSection;