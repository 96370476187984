import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import HomeThreeSingleService from '../../../components/HomeThreeSingleService/HomeThreeSingleService';

const HomeThreeServices = () => {
   return (
      <>
         <section className="services__area-2 mt--120 pt-270 pb-140 p-relative" style={{ background: `url(assets/img/bg/wave-bg-2.png)`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
            <div className="container">
               <div className="row g-0">

                  <HomeThreeSingleService image="27" title="Infographic Animation" subtitle="Bringing Ideas to Life with Creativity and Precision" />
                  <HomeThreeSingleService image="28" title="3D Animation" subtitle="Elevate Your Visuals with Depth and Realism" />
                  <HomeThreeSingleService image="29" title="Video Editing" subtitle="Transform Your Footage into Professional Masterpieces" />
                  <HomeThreeSingleService image="30" title="Motion Graphics" subtitle="Enhance Your Content with Dynamic Visual Effects" />

               </div>
               <div className="about__area-2 pt-130">
                  <div className="row">
                     <div className="col-xl-7 col-lg-6">
                        <div className="about__thumb-2 p-relative m-img">
                           <img src="assets/img/about/about-6.png" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="about__content">
                           <div className="section__title section__title-3 mb-25">
                              <h2 >Power of Animation with JF DevOps</h2>
                           </div>
                           <p >At JF DevOps, we turn your vision into captivating 2D and 3D animations. Discover how we bring your ideas to life.</p>
                           <div className="about__list">
                              <ul>
                                 <li><span><i > <FaCheck /> </i>High-Quality Production</span></li>
                                 <li><span><i > <FaCheck /> </i>Versatile Styles</span></li>
                                 <li><span><i > <FaCheck /> </i>Engaging Visuals</span></li>
                                 <li><span><i > <FaCheck /> </i>Effective Communication</span></li>
                                 <li><span><i > <FaCheck /> </i>On-Time Delivery</span></li>
                                 <li><span><i > <FaCheck /> </i>Creative Expertise</span></li>
                              </ul>
                           </div>
                           <Link to="/about" className="z-btn">What we do<i > <CgArrowLongRight /> </i></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeServices;