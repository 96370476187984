import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import HomeSevenSingleService from '../../../components/HomeSevenSingleService/HomeSevenSingleService';

const HomeSevenServices = () => {
   return (
      <>
         <section className="services__area-2 mt--120 pt-270 pb-140 p-relative" style={{ background: `url(assets/img/bg/wave-bg-2.png)`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
            <div className="container">
               <div className="section__title section__title-3 mb-25">
                  <h2 >Services that drive business results</h2>
               </div>
               <div className="row g-0">

                  <HomeSevenSingleService image="19" title="Threat Detection & Response" subtitle="Proactive Monitoring" />
                  <HomeSevenSingleService image="20" title="Risk Assessment & Management" subtitle="Identify & Mitigate Vulnerabilities" />
                  <HomeSevenSingleService image="21" title="Penetration Testing" subtitle="Simulated Attacks to Strengthen Security" />
                  <HomeSevenSingleService image="22" title="Security Consulting" subtitle="Expert Guidance & Strategic Planning" />
                  <HomeSevenSingleService image="23" title="Compliance & Governance" subtitle="Ensure Regulatory Adherence" />
                  <HomeSevenSingleService image="24" title="Managed Security Services" subtitle="24/7 Security Operations & Support" />
                  <HomeSevenSingleService image="25" title="Incident Response & Forensics" subtitle="Swift Action & Detailed Investigation" />
                  <HomeSevenSingleService image="26" title="Security Awareness Training" subtitle="Empower Your Team" />

               </div>
               <div className="about__area-2 pt-130">
                  <div className="row">
                     <div className="col-xl-7 col-lg-6">
                        <div className="about__thumb-2 p-relative m-img">
                           <img src="assets/img/about/about-5.png" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="about__content">
                           <div className="section__title section__title-3 mb-25">
                              <h2 >Startup & Early Consulting Business Package</h2>
                           </div>
                           <p >Our mission is to deliver innovative and reliable cybersecurity services that protect your organization from cyber threats, ensuring your data remains secure and your operations run smoothly.</p>
                           <div className="about__list">
                              <ul>
                                 <li><span><i > <FaCheck /> </i>Risk Assessment & Management</span></li>
                                 <li><span><i > <FaCheck /> </i>Firewall & Intrusion Detection</span></li>
                                 <li><span><i > <FaCheck /> </i>Data Encryption & Backup</span></li>
                                 <li><span><i > <FaCheck /> </i>Multi-Factor Authentication (MFA)</span></li>
                                 <li><span><i > <FaCheck /> </i>Security Awareness Training</span></li>
                                 <li><span><i > <FaCheck /> </i>Incident Response Plan</span></li>
                                 <li><span><i > <FaCheck /> </i>Regular Patch Management</span></li>
                              </ul>
                           </div>
                           <Link to="/about" className="z-btn">What we do<i > <CgArrowLongRight /> </i></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSevenServices;