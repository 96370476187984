import React from 'react';
import { Link } from 'react-router-dom';

const HomeFiveFaq = () => {
   return (
      <>
         <section className="faq__area pb-200 pt-75">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-5">
                     <div className="faq__content">
                        <div className="section-title mb-45">
                           <h2>Do you have <br /> Any question</h2>
                           <p>We guide you through uncertainties with our expert team.</p>
                        </div>
                        <Link to="/about" className="z-btn z-btn-border">All Question</Link>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7 offset-xl-2 offset-lg-1">
                     <div className="faq__accordion p-relative">

                        <div className="accordion" id="accordionExample" >
                           <div className="card accordion-item">
                              <div className="card-header accordion-header" id="acc_1" >
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                       What types of data science services do you offer?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>We offer a range of data science services, including predictive analytics, customer insights, business intelligence, and process optimization. Our goal is to leverage data to drive strategic decisions and improve business performance.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_2">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                       How do you use Power BI in your data science projects?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Power BI is used to create interactive dashboards and visualizations, providing clear and actionable insights from your data. It helps in tracking performance metrics and making data-driven decisions.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_3">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                       Why do you use Python for data analysis?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Python is chosen for its flexibility and extensive libraries, which are essential for data manipulation, statistical analysis, and building machine learning models. It allows us to handle complex data tasks efficiently.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_4">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-controls="collapse_4">
                                       How does Advanced Excel fit into your data science services?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Advanced Excel is used for detailed data analysis and reporting. Its features, like pivot tables and advanced formulas, help in managing and analyzing data effectively, complementing other tools and methods used in our projects.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFiveFaq;