import React from 'react';
import { FaFacebookF, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const BlogFooter = () => {
   return (
      <>
         <footer>
            <div className="footer__area black-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".2s">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <Link to="/home">
                                       <img src="assets/img/logo/logo.png" alt="" />
                                    </Link>
                                 </div>
                              </div>
                              <div className="footer__widget-content footer__widget-content-2">
                                 <p className="mb-30">Copyright © 2023 All Rights Reserved passion by JF DevOps</p>
                                 <div className="footer__social footer__social-2 theme-social mb-30">
                                    <ul>
                                       <li>
                                          <a href="https://wa.me/+917760288526">
                                             <i ><FaWhatsapp /> </i>
                                             <i ><FaWhatsapp /> </i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="https://www.linkedin.com/company/jf-devops/?viewAsMember=true">
                                             <i ><FaLinkedin /> </i>
                                             <i ><FaLinkedin /> </i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href="https://wa.me/+917760288526">
                                             <i ><FaFacebookF /></i>
                                             <i ><FaFacebookF /></i>
                                          </a>

                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title text-white">
                                 <h4>Company</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                       <li><Link to="/about">About Us</Link></li>
                                       <li><Link to="/home">Testimonials</Link></li>
                                       <li><Link to="/contact">Consulting</Link></li>
                                       <li><Link to="/contact">Partners</Link></li>
                                       <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".6s">
                              <div className="footer__widget-title text-white">
                                 <h4>Resources</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                       <li><Link to="/home">Customers</Link></li>
                                       <li><Link to="/contact">Pricing</Link></li>
                                       <li><Link to="/contact">News</Link></li>
                                       <li><Link to="/contact">Help desk</Link></li>
                                       <li><Link to="/contact">Support</Link></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1 ">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".8s">
                              <div className="footer__widget-title text-white">
                                 <h4>Use Cases</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links footer__links-2">
                                    <ul>
                                       <li><Link to="/homeTwo">Brand & Digital</Link></li>
                                       <li><Link to="/homeThree">Motion Media</Link></li>
                                       <li><Link to="/headerStyleFour">Full Stack Solution</Link></li>
                                       <li><Link to="/headerStyleFive">Data Science</Link></li>
                                       <li><Link to="/headerStyleSix">AL/ML</Link></li>
                                       <li><Link to="/headerStyleSeven">Cyber Security</Link></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright footer__copyright-2">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-12 text-center">
                           <div className="footer__copyright-text footer__copyright-text-2">
                              <p>Copyright © 2023 All Rights Reserved passion by <Link to="/home">JF DevOps</Link></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer >
      </>
   );
};

export default BlogFooter;