import React from 'react';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import HomeThreeSingleService from '../../../components/HomeThreeSingleService/HomeThreeSingleService';

const HomeSixServices = () => {
   return (
      <>
         <section className="services__area-2 mt--120 pt-270 pb-140 p-relative" style={{ background: `url(assets/img/bg/wave-bg-2.png)`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
            <div className="container">
               <div className="row g-0">

                  <HomeThreeSingleService image="1" title="AI-powered Mobile & Web App Development" subtitle="Seamless Integration of AI for Enhanced User Experience" />
                  <HomeThreeSingleService image="2" title="AI Software Development" subtitle="Innovative Solutions with Advanced AI Technologies" />
                  <HomeThreeSingleService image="3" title="Machine Learning Development" subtitle="Building Intelligent Systems with Data-Driven Insights" />
                  <HomeThreeSingleService image="4" title="Enterprise AI Solutions Development" subtitle="Scalable AI Solutions for Business Transformation" />
                  <HomeThreeSingleService image="5" title="Generative AI Development" subtitle="Creative and Adaptive AI for Next-Gen Applications" />
                  <HomeThreeSingleService image="6" title="Smart AI Assistants & Chatbot" subtitle="Intelligent Interfaces for Improved Interaction" />
                  <HomeThreeSingleService image="7" title="Data Governance & Support" subtitle="Robust Management and Protection of Your Data Assets" />
                  <HomeThreeSingleService image="8" title="AI Consulting & Development" subtitle="Strategic Insights and Tailored AI Solutions for Your Needs" />

               </div>
               <div className="about__area-2 pt-130">
                  <div className="row">
                     <div className="col-xl-7 col-lg-6">
                        <div className="about__thumb-2 p-relative m-img">
                           <img src="assets/img/about/about-2.png" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="about__content">
                           <div className="section__title section__title-3 mb-25">
                              <h2 >Business Benefits of AI & ML Solutions</h2>
                           </div>
                           <p >We help you weather today's uncertainty through our best team intelligence and needs.</p>
                           <div className="about__list">
                              <ul>
                                 <li><span><i > <FaCheck /> </i>Smarter Decisions</span></li>
                                 <li><span><i > <FaCheck /> </i>Greater Efficiency</span></li>
                                 <li><span><i > <FaCheck /> </i>Personalized Service</span></li>
                                 <li><span><i > <FaCheck /> </i>Predictive Insights</span></li>
                              </ul>
                           </div>
                           <Link to="/about" className="z-btn">What we do<i > <CgArrowLongRight /> </i></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSixServices;