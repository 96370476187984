import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import HomeFourSingleService from "../../../components/HomeThreeSingleService/HomeThreeSingleService";

const HomeFourServices = () => {
   return (
      <>
         <section
            className="services__area-2 mt--120 pt-270 pb-140 p-relative"
            style={{
               background: `url(assets/img/bg/wave-bg-2.png)`,
               backgroundPosition: "bottom",
               backgroundSize: "cover",
            }}
         >
            <div className="container">
               <div className="row g-0">
                  <HomeFourSingleService
                     image="15"
                     title="Predictive Analytics"
                     subtitle="Forecast Future Trends with Precision"
                  />
                  <HomeFourSingleService
                     image="16"
                     title="Machine Learning Solutions"
                     subtitle="Intelligent Systems that Evolve"
                  />
                  <HomeFourSingleService
                     image="17"
                     title="Data Visualization"
                     subtitle="Transform Complex Data into Clear Insights"
                  />
                  <HomeFourSingleService
                     image="18"
                     title="Data Engineering"
                     subtitle="Build and Maintain Robust Data Infrastructures"
                  />
               </div>
               <div className="about__area-2 pt-130">
                  <div className="row">
                     <div className="col-xl-7 col-lg-6">
                        <div className="about__thumb-2 p-relative m-img">
                           <img src="assets/img/about/about-4.png" alt="" />
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="about__content">
                           <div className="section__title section__title-3 mb-25">
                              <h2>Build Evolving Intelligent Systems</h2>
                           </div>
                           <p>
                              Transform data into actionable insights with our expert solutions in analytics, machine learning, and visualization. Drive smarter decisions and growth.
                           </p>
                           <div className="about__list">
                              <ul>
                                 <li>
                                    <span>
                                       <i>
                                          {" "}
                                          <FaCheck />{" "}
                                       </i>
                                       High Accuracy
                                    </span>
                                 </li>
                                 <li>
                                    <span>
                                       <i>
                                          {" "}
                                          <FaCheck />{" "}
                                       </i>
                                       Scalability
                                    </span>
                                 </li>
                                 <li>
                                    <span>
                                       <i>
                                          {" "}
                                          <FaCheck />{" "}
                                       </i>
                                       Efficiency
                                    </span>
                                 </li>
                              </ul>
                           </div>
                           <Link to="/about" className="z-btn">
                              What we do
                              <i>
                                 {" "}
                                 <CgArrowLongRight />{" "}
                              </i>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFourServices;
