import React from 'react';
import { Link } from 'react-router-dom';

const HomeSevenFaq = () => {
   return (
      <>
         <section className="faq__area pb-200 pt-75">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-5">
                     <div className="faq__content">
                        <div className="section-title mb-45">
                           <h2>Do you have <br /> Any question</h2>
                           <p>We help you weather today's uncertainty through our best team.</p>
                        </div>
                        <Link to="/about" className="z-btn z-btn-border">All Question</Link>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7 offset-xl-2 offset-lg-1">
                     <div className="faq__accordion p-relative">

                        <div className="accordion" id="accordionExample" >
                           <div className="card accordion-item">
                              <div className="card-header accordion-header" id="acc_1" >
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                       What is cybersecurity?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Cybersecurity involves protecting your computer systems and networks from digital attacks, unauthorized access, and damage.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_2">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                       Why is cybersecurity important for my business?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Cybersecurity is crucial to protect sensitive data, maintain customer trust, and ensure the smooth operation of your business without disruptions from cyber threats.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_3">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                       How can I assess my cybersecurity needs?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>We offer comprehensive risk assessments to evaluate your current security posture and identify areas for improvement.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_4">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                       What are the benefits of managed security services?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Managed security services provide continuous monitoring and support, allowing you to focus on your core business activities while we handle your cybersecurity.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSevenFaq;