import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeFooter from '../HomeThree/HomeThreeFooter/HomeThreeFooter';
import HomeTwoTestimonial from '../HomeTwo/HomeTwoTestimonial/HomeTwoTestimonial';
import StyleFiveHeader from './StyleFiveHeader/StyleFiveHeader';
import HomeFiveHeroSection from './HomeFiveHeroSection/HomeFiveHeroSection';
import HomeFiveServices from './HomeFiveServices/HomeFiveServices';
import HomeFiveSecondServices from './HomeFiveSecondServices/HomeFiveSecondServices';
import HomeFiveProjects from './HomeFiveProjects/HomeFiveProjects';
import HomeFiveFaq from './HomeFiveFaq/HomeFiveFaq';



const HeaderStyleFive = () => {
   return (
      <>
         <PageHelmet pageTitle="Header Style Five" />

         <StyleFiveHeader />
         <HomeFiveHeroSection />
         <HomeFiveServices />
         <HomeFiveSecondServices />
         <HomeTwoTestimonial />
         <HomeFiveProjects />
         <HomeFiveFaq />
         <CommonCtaArea />
         <HomeThreeFooter />
      </>
   );
};

export default HeaderStyleFive;