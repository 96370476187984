import React, { useState } from 'react';
import { FaEnvelope, FaFacebookF, FaLinkedin, FaPhoneAlt, FaSearch, FaWhatsapp } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import useGlobalContext from '../../../hooks/useGlobalContext';
import Sidebar from '../../../components/Sidebar/Sidebar';

const StyleHeaderSix = () => {

   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   return (
      <>
         <header>
            <div className="header__top header__top-4 d-none d-md-block header__style-6">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-xl-6 col-lg-5 col-md-4">
                        <div className="header__social">
                           <ul>
                              <li><a href="https://wa.me/+917760288526"><i ><FaWhatsapp /></i> </a></li>
                              <li><a href="https://www.linkedin.com/company/jf-devops/?viewAsMember=true"><i ><FaLinkedin /> </i></a> </li>
                              <li><a href="https://wa.me/+917760288526"><i ><FaFacebookF /></i></a></li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-7 col-md-8">
                        <div className="header__info f-right">
                           <ul>
                              <li>
                                 <a href="tel:(+91)-776-028-8526">
                                    <i > <FaPhoneAlt /> </i>
                                    (+91) 776 028 8526
                                 </a>
                              </li>
                              <li>
                                 <a href="mailto:info@jfdevops.com">
                                    <i > <FaEnvelope /> </i>
                                    info@consulting.com
                                 </a>
                              </li>
                              <li>
                                 <a href="/headerstylesix" className="search-toggle">
                                    <i > <FaSearch /> </i>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div className="logo">
                           <NavLink to="/">
                              <img src="assets/img/logo/logo-gradient.png" alt="logo" />
                           </NavLink>
                        </div>
                        <div className="logo-gradient">
                           <NavLink to="/">
                              <img src="assets/img/logo/logo-gradient.png" alt="logo" />
                           </NavLink>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                        <div className="header__bottom-right d-flex justify-content-end align-items-center">
                           <div className="main-menu header__style-4-menu header__style-1-menu header_style_six_menu">
                              <nav id="mobile-menu">
                                 <ul>
                                    <li>
                                       <NavLink to="/home">What we do</NavLink>
                                       <ul className="submenu">
                                          <li>
                                             <NavLink to="/home">What we do</NavLink>
                                          </li>
                                          <li>
                                             <NavLink to="/homeTwo">Brand & Digital</NavLink>
                                          </li>
                                          <li>
                                             <NavLink to="/homeThree">Motion Media</NavLink>
                                          </li>
                                          <li>
                                             <NavLink to="/headerStyleFour">
                                                Full Stack Solutions{" "}
                                             </NavLink>
                                          </li>
                                          <li>
                                             <NavLink to="/headerStyleFive">
                                                Data & Analytics
                                             </NavLink>
                                          </li>
                                          <li>
                                             <NavLink to="/headerStyleSix">AL/ML</NavLink>
                                          </li>
                                          <li>
                                             <NavLink to="/headerStyleSeven">
                                                Cyber Security
                                             </NavLink>
                                          </li>
                                       </ul>
                                    </li>
                                    <li><NavLink to="/about">About Us </NavLink></li>
                                    <li>
                                       <NavLink to="/services">Services</NavLink>
                                       <ul className="submenu">
                                          <li><NavLink to="/services">Services</NavLink></li>
                                          <li><NavLink to="/servicesDetails">Services Details</NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <NavLink to="/portfolio">Insights</NavLink>
                                       <ul className="submenu">
                                          <li><NavLink to="/portfolio">portfolio</NavLink></li>
                                          <li><NavLink to="/portfolioDetails">portfolio Details</NavLink></li>
                                          <li><NavLink to="/team">team</NavLink></li>
                                          <li><NavLink to="/teamDetails">team Details</NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <NavLink to="/blogs">Blog</NavLink>
                                       <ul className="submenu">
                                          <li><NavLink to="/blogs">Blog</NavLink></li>
                                          <li><NavLink to="/blogDetails">Blog Details</NavLink></li>
                                       </ul>
                                    </li>
                                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                                 </ul>
                              </nav>
                           </div>
                           <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                              <Link to="/contact" className="z-btn">Get a Quote</Link>
                           </div>
                           <div onClick={handleShow} className="sidebar__menu d-lg-none">
                              <div className="sidebar-toggle-btn sidebar-toggle-btn-3" id="sidebar-toggle">
                                 <span className="line"></span>
                                 <span className="line"></span>
                                 <span className="line"></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="header__search-wrapper">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <form action="#">
                           <input type="text" placeholder="Your Keywords" />
                           <button type="button"><i > <FaSearch /> </i></button>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <div className="body-overlay-2"></div>
         </header>


         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default StyleHeaderSix;