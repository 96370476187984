import React from 'react';
import { FaFacebookF, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import BlogLeftSideBar from '../../Blogs/BlogsArea/BlogLeftSideBar';

const BlogDetailsArea = () => {
   return (
      <>
         <section className="blog__area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 col-lg-8">
                     <div className="blog__details-wrapper mr-50">
                        <div className="blog__text mb-45">
                           <p>Incorporating advanced IT services into your business strategy can lead to significant improvements in efficiency, security, and growth. At JF DevOps, we are dedicated to providing innovative and reliable IT solutions tailored to your unique needs. Contact us today to discover how we can help you unlock new possibilities and drive success in the digital age.</p>
                        </div>
                        <div className="blog__quote mb-40 p-relative white-bg fix">
                           <div className="blog__quote-line">
                              <img className="line-1" src="assets/img/icon/blog/line-1.png" alt="" />
                              <img className="line-2" src="assets/img/icon/blog/line-2.png" alt="" />
                           </div>
                           <div className="quote mb-10">
                              <img src="assets/img/icon/blog/quote-1.png" alt="" />
                           </div>
                           <div className="quote-2 p-absolute">
                              <img src="assets/img/icon/blog/quote-2.png" alt="" />
                           </div>
                           <blockquote>
                              <p>"In a complete digital world, success lies in seamlessly integrating innovation with strategy. Our mission is to empower businesses by providing cutting-edge digital solutions that transform challenges into opportunities and drive growth in an ever-evolving landscape."</p>
                           </blockquote>
                           <h4>AI</h4>
                        </div>
                        <div className="blog__text mb-40">
                           <p>In today’s fast-paced digital world, leveraging the right IT services can be the key to unlocking your business's full potential. From streamlining operations to enhancing cybersecurity, IT services are crucial for staying competitive and agile. Here’s how our comprehensive IT solutions can drive your business forward.</p>
                        </div>
                        <div className="blog__details-thumb w-img mb-45">
                           <img src="assets/img/blog/details/b-d-1.jpg" alt="" />
                        </div>
                        <div className="blog__text mb-40">
                           <h3>JF DevOps as an essential service for transforming development workflows</h3>
                           <p>JF DevOps is the only service you’ll ever need to revolutionize your development pipeline. By integrating best practices with technology, we streamline your processes, enhance collaboration, and accelerate your delivery, ensuring optimal performance and efficiency in every project</p>

                           <p> <span>With JF DevOps, </span>experience the ultimate in efficiency and innovation. Our all-encompassing service optimizes your development lifecycle, fosters seamless team collaboration, and accelerates time-to-market, making it the only solution you need for transformative DevOps success.</p>
                        </div>
                        <div className="blog__tag mb-30">
                           <span>Tag : </span>
                           <a href="/blogDetails">Blog</a>
                           <a href="/blogDetails">Creative</a>
                           <a href="/blogDetails">Portfolio</a>
                           <a href="/blogDetails">Theme</a>
                        </div>
                        <div className="blog__share d-flex align-items-center mb-30">
                           <span>Share : </span>
                           <div className="blog__social theme-social d-inline-block">
                              <ul>
                                 <li>
                                    <a href="https://wa.me/+917760288526">
                                       <i ><FaWhatsapp /> </i>
                                       <i ><FaWhatsapp /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://www.linkedin.com/company/jf-devops/?viewAsMember=true">
                                       <i ><FaLinkedin /> </i>
                                       <i ><FaLinkedin /> </i>
                                    </a>
                                 </li>
                                 <li>
                                    <a href="https://wa.me/+917760288526">
                                       <i ><FaFacebookF /></i>
                                       <i ><FaFacebookF /></i>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="blog__author mb-95 d-sm-flex">
                           <div className="blog__author-img mr-30">
                              <img src="assets/img/blog/author/author-1.jpg" alt="" />
                           </div>
                           <div className="blog__author-content">
                              <h5>Sophie Ianiro</h5>
                              <span>Author</span>
                              <p>I said cracking goal down the pub blag cheeky bugger at public school A bit of how's your father boot.!</p>
                           </div>
                        </div>
                        <div className="post-comments mb-95" data-wow-delay=".6s">
                           <div className="post-comment-title mb-40">
                           </div>
                        </div>
                        <div className="post-comment-form">
                           <h4>Leave a Reply </h4>
                           <span>Your email address will not be published.</span>
                           <form action="#">
                              <div className="row">
                                 <div className="col-xl-12">
                                    <div className="post-input">
                                       <textarea placeholder="Your message..."></textarea>
                                    </div>
                                 </div>
                                 <div className="col-xl-6 col-md-6">
                                    <div className="post-input">
                                       <input type="text" placeholder="Your Name" />
                                    </div>
                                 </div>
                                 <div className="col-xl-6 col-md-6">
                                    <div className="post-input">
                                       <input type="email" placeholder="Your Email" />
                                    </div>
                                 </div>
                                 <div className="col-xl-12">
                                    <div className="post-input">
                                       <input type="text" placeholder="Website" />
                                    </div>
                                 </div>
                                 <div className="col-xl-12">
                                    <div className="post-check mb-40">
                                       <input type="checkbox" />
                                       <span>Save my name, email, and website in this browser for the next time I comment.</span>
                                    </div>
                                 </div>
                              </div>

                              <button type="button" className="z-btn">Send Message</button>
                           </form>
                        </div>
                     </div>
                  </div>

                  <BlogLeftSideBar />
               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;