import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeFooter from '../HomeThree/HomeThreeFooter/HomeThreeFooter';
import StyleHeaderSix from './StyleHeaderSix/StyleHeaderSix';
import HomeSixProjects from './HomeSixProjects/HomeSixProjects';
import HomeSixServices from './HomeSixServices/HomeSixServices';
import HomeSixSecondServices from './HomeSixSecondServices/HomeSixSecondServices';
import HomeSixFaq from './HomeSixFaq/HomeSixFaq';

const HeaderStyleSix = () => {
   return (
      <>
         <PageHelmet pageTitle="Header Style Six" />

         <StyleHeaderSix />
         <HomeSixProjects />
         <HomeSixServices />
         <HomeSixSecondServices />
         <HomeSixFaq />
         <CommonCtaArea />
         <HomeThreeFooter />
      </>
   );
};

export default HeaderStyleSix;