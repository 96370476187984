import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeFourFaq from '../HomeFour/HomeFourFaq/HomeFourFaq';
import HomeFourFooter from '../HomeFour/HomeFourFooter/HomeFourFooter';
import HomeFourHeader from '../HomeFour/HomeFourHeader/HomeFourHeader';
import HomeFourHeroSection from '../HomeFour/HomeFourHeroSection/HomeFourHeroSection';
import HomeFourProjects from '../HomeFour/HomeFourProjects/HomeFourProjects';
import HomeFourSecondServices from '../HomeFour/HomeFourSecondServices/HomeFourSecondServices';
import HomeFourServices from '../HomeFour/HomeFourServices/HomeFourServices';

const HeaderStyleFour = () => {
   return (
      <>
         <PageHelmet pageTitle="Header Style Four" />

         <HomeFourHeader h4class="header__style-4" btn_text="Sign Up" btn_class="z-btn-border" />
         <HomeFourHeroSection />
         <HomeFourServices />
         <HomeFourProjects />
         <HomeFourSecondServices />
         <HomeFourFaq />
         <CommonCtaArea />
         <HomeFourFooter />
      </>
   );
};

export default HeaderStyleFour;