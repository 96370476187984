import React from 'react';
import { Link } from 'react-router-dom';

const HomeFourFaq = () => {
   return (
      <>
         <section className="faq__area pb-200 pt-75">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-5">
                     <div className="faq__content">
                        <div className="section-title mb-45">
                           <h2>Do you have <br /> Any question</h2>
                           <p>We guide you through uncertainties with our expert team.</p>
                        </div>
                        <Link to="/about" className="z-btn z-btn-border">All Question</Link>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7 offset-xl-2 offset-lg-1">
                     <div className="faq__accordion p-relative">

                        <div className="accordion" id="accordionExample" >
                           <div className="card accordion-item">
                              <div className="card-header accordion-header" id="acc_1" >
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                       Why should I choose Full-Stack Development?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>Choosing full-stack development provides a comprehensive approach to building web applications. It ensures seamless integration between the front-end and back-end, leading to more cohesive and efficient solutions.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_2">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                       What technologies do you use for Full-Stack Development?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>We utilize a variety of technologies including HTML, CSS, JavaScript, and frameworks like React, Angular, and Vue.js for the front-end. For the back-end, we use Node.js, Express, Python, Ruby on Rails, and PHP. Databases are managed with SQL (MySQL, PostgreSQL) or NoSQL (MongoDB) solutions.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_3">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                       How long does it take to develop a full-stack application?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>The timeline for developing a full-stack application varies based on the project’s complexity and requirements. We provide detailed timelines and milestones during the initial consultation and keep you updated throughout the development process.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_4">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-controls="collapse_4">
                                       How do you support clients after the project is completed?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>We offer continuous customer support after project completion to address any issues, provide updates, and assist with any questions or additional needs that may arise.</p>
                                 </div>
                              </div>
                           </div>

                           <div className="card">
                              <div className="card-header" id="acc_5">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-controls="collapse_5">
                                       What is your approach to ensuring data security?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_5" className="collapse" aria-labelledby="acc_5" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>We implement best practices for data security, including secure coding standards, regular security audits, and encryption protocols to protect your data and ensure compliance with industry standards.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFourFaq;