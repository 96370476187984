import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactArea = () => {
   const [formData, setFormData] = useState({
      name: '',
      email: '',
      mobile: '',
      message: ''
   });
   const [notification, setNotification] = useState(null);
   const formRef = useRef(null);

   const handleFormSubmit = (event) => {
      event.preventDefault();

      if (formData.name && formData.email && formData.mobile && formData.message) {
         sendEmail();
      } else {
         setNotification('Please fill in all fields!');
         setTimeout(() => {
            setNotification(null);
         }, 2000);
      }
   };

   const sendEmail = () => {
      emailjs.sendForm('service_l9q7m7s', 'template_7yofcm8', formRef.current, 'XSzEj9hzPDrw1tOn-')
         .then((response) => {
            console.log('Email sent successfully:', response.status, response.text);
            setFormData({
               name: '',
               email: '',
               mobile: '',
               message: ''
            });
            setNotification('Email sent successfully!');
            setTimeout(() => {
               setNotification(null);
            }, 2000);
         })
         .catch((err) => {
            console.error('Error sending email:', err);
            setNotification('Error sending email!');
            setTimeout(() => {
               setNotification(null);
            }, 2000);
         });
   };

   const handleInputChange = (event) => {
      setFormData({
         ...formData,
         [event.target.name]: event.target.value
      });
   };
   // ... rest of your code

   return (
      <React.Fragment>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        <iframe title='contact' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.1489811928386!2d77.69636188507756!3d12.96231708248676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11e6982ac5df%3A0xb52fcb3ad37e0ec3!2sPro%20Stack%20Academy!5e0!3m2!1sen!2sus!4v1724346374895!5m2!1sen!2sus"></iframe>
                        <div className="contact__wrapper d-md-flex justify-content-between wow fadeInUp" data-wow-delay=".3s">
                           <div className="contact__info mr-100">
                              <h3>Get in touch</h3>
                              <ul>
                                 <li key="address">
                                    <h4>Address</h4>
                                    <p>RJ Garden, 1st Floor, Marathahalli,
                                       <br /> Bengaluru, Karnataka 560037</p>
                                 </li>
                                 <li key="call-us">
                                    <h4>call us</h4>
                                    <p><a href="tel:(+91)-776-028-8526">(+91) 77602 88526</a></p>
                                 </li>
                                 <li key="email">
                                    <h4>Email Address</h4>
                                    <p><a href="mailto:info@jfdevops.com">info@jfdevops.com</a></p>
                                 </li>
                              </ul>
                           </div>
                           <div className="contact__form">
                              <form ref={formRef} onSubmit={handleFormSubmit}>
                                 <input
                                    type="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Your Name"
                                    aria-label="Your Name"
                                    aria-required="true"
                                 />
                                 <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Your Email"
                                    aria-label="Your Email"
                                    aria-required="true"
                                 />
                                 <input
                                    type="tel"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Your mobile"
                                    aria-label="Your mobile"
                                    aria-required="true"
                                 />
                                 <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Your Message"
                                    aria-label="Your Message"
                                    aria-required="true"
                                 ></textarea>
                                 <button type="submit" className="z-btn">Send Message</button>
                              </form>
                              {notification && (
                                 <div className={`notification ${notification.includes('successfully') ? 'success' : 'error'}`}>
                                    {notification}
                                 </div>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   );
};

export default ContactArea;