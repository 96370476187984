
const portfolioData = [
    {
        id: 1,
        img: 'assets/img/portfolio/port-1.jpg',
        title: 'Mobile App',
        subtitle: 'UI/UX Design',
        category: 'consulting',
    },
    {
        id: 2,
        img: 'assets/img/portfolio/port-2.jpg',
        title: '3D Icon Spectrum',
        subtitle: 'UI Design',
        category: 'facilitation',
    },
    {
        id: 3,
        img: 'assets/img/portfolio/port-3.jpg',
        title: 'DevIcons',
        subtitle: 'Studio',
        category: 'consulting',
    },
    {
        id: 4,
        img: 'assets/img/portfolio/port-4.jpg',
        title: 'DataSphere CRM ',
        subtitle: 'Dashboard',
        category: 'strategy',
    },
    {
        id: 5,
        img: 'assets/img/portfolio/port-5.jpg',
        title: 'All-in-One',
        subtitle: 'Web Development',
        category: 'strategy',
    },
    {
        id: 6,
        img: 'assets/img/portfolio/port-6.jpg',
        title: 'Landing Animation',
        subtitle: 'Stories to Life',
        category: 'strategy',
    },
    {
        id: 7,
        img: 'assets/img/portfolio/port-7.jpg',
        title: 'Transformative 3D Design',
        subtitle: 'Distinctive Identity',
        category: 'facilitation',
    },
    {
        id: 8,
        img: 'assets/img/portfolio/port-8.jpg',
        title: 'Dimensional Impact',
        subtitle: 'Crafting 3D Experiences that Define Your Brand',
        category: 'facilitation',
    },
    {
        id: 9,
        img: 'assets/img/portfolio/port-1.jpg',
        title: 'New Landing Screen',
        subtitle: 'UI Design',
        category: 'strategy',
    },
    {
        id: 10,
        img: 'assets/img/portfolio/port-3.jpg',
        title: 'A Classic Cup',
        subtitle: 'Photography',
        category: 'design',
    },
    {
        id: 11,
        img: 'assets/img/portfolio/port-5.jpg',
        title: 'New Landing Screen',
        subtitle: 'UI UX Design',
        category: 'design',
    },
    {
        id: 12,
        img: 'assets/img/portfolio/port-7.jpg',
        title: 'Branding New Clock',
        subtitle: 'Photography',
        category: 'design',
    },
]

export default portfolioData;