import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const HomeFiveProjects = () => {
   // slick setting
   const settings = {
      autoplay: false,
      autoplaySpeed: 100,
      dots: false,
      fade: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 2
            }
         },
      ]
   }
   const testimonialData = [
      {
         id: 1,
         title: <h3><Link to="/portfolioDetails">Data Collection</Link></h3>,
         img: "assets/img/project/project-6.png",
      },
      {
         id: 2,
         title: <h3><Link to="/portfolioDetails">Data Cleaning</Link></h3>,
         img: "assets/img/project/project-7.png",
      },
      {
         id: 3,
         title: <h3><Link to="/portfolioDetails">Data Exploration</Link></h3>,
         img: "assets/img/project/project-8.png",
      },
      {
         id: 4,
         title: <h3><Link to="/portfolioDetails">Model Development</Link></h3>,
         img: "assets/img/project/project-9.png",
      },
      {
         id: 5,
         title: <h3><Link to="/portfolioDetails">Model Evaluation</Link></h3>,
         img: "assets/img/project/project-10.png",
      },
      {
         id: 6,
         title: <h3><Link to="/portfolioDetails">Deployment</Link></h3>,
         img: "assets/img/project/project-11.png",
      },
      {
         id: 7,
         title: <h3><Link to="/portfolioDetails">Monitoring and Maintenance</Link></h3>,
         img: "assets/img/project/project-12.png",
      },
   ]
   return (
      <>
         <section className="project__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7">
                     <div className="section-title section__title-3 mb-70">
                        <h2>Turning Data into<br />Actionable Insights</h2>
                        <p>We provide end-to-end web development services, integrating both front-end and back-end technologies <br /> to build robust, scalable applications tailored to your needs. </p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <Slider className='project__slider' {...settings}>
                        {
                           testimonialData.map((testimonial, index) => {
                              return <div key={index} className="project__item grey-bg-19">
                                 <div className="project__content">
                                    <div className="project__no">
                                       <h5>{testimonial.id}</h5>
                                    </div>
                                    {testimonial.title}
                                 </div>
                                 <div className="project__thumb m-img">
                                    <img src={testimonial.img} alt="" />
                                 </div>
                              </div>
                           })
                        }

                     </Slider>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeFiveProjects;