import React from 'react';
import { Link } from 'react-router-dom';

const HomeSixFaq = () => {
   return (
      <>
         <section className="faq__area pb-200 pt-75">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-5">
                     <div className="faq__content">
                        <div className="section-title mb-45">
                           <h2>Do you have <br /> Any question</h2>
                           <p>We help you weather today's uncertainty through our best team.</p>
                        </div>
                        <Link to="/about" className="z-btn z-btn-border">All Question</Link>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-7 offset-xl-2 offset-lg-1">
                     <div className="faq__accordion p-relative">

                        <div className="accordion" id="accordionExample" >
                           <div className="card accordion-item">
                              <div className="card-header accordion-header" id="acc_1" >
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                                       What types of businesses can benefit from AI and ML services?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_1" className="collapse show" aria-labelledby="acc_1" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>AI and ML are versatile technologies that can benefit businesses across various industries, including finance, healthcare, retail, manufacturing, and more. Any business looking to optimize operations, enhance customer experiences, or gain deeper insights from data can leverage AI and ML to achieve these goals.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_2">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="true" aria-controls="collapse_2">
                                       How long does it take to implement AI and ML solutions?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_2" className="collapse" aria-labelledby="acc_2" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>The implementation timeline for AI and ML solutions varies depending on the complexity of the project and the specific needs of your business. Typically, projects can range from a few weeks for smaller, straightforward solutions to several months for more complex, custom implementations.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_3">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="true" aria-controls="collapse_3">
                                       Do I need a large dataset to benefit from AI and ML?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_3" className="collapse" aria-labelledby="acc_3" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>While having a large dataset can enhance the accuracy of AI and ML models, businesses can still benefit from these technologies with smaller datasets. Our experts can work with the available data and use advanced techniques to extract valuable insights and drive meaningful results.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="card">
                              <div className="card-header" id="acc_4">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="true" aria-controls="collapse_4">
                                       How do AI and ML improve decision-making in my business?
                                    </button>
                                 </h5>
                              </div>

                              <div id="collapse_4" className="collapse" aria-labelledby="acc_4" data-bs-parent="#accordionExample">
                                 <div className="card-body accordion-body">
                                    <p>AI and ML analyze vast amounts of data to uncover patterns, trends, and insights that might not be immediately apparent. By providing data-driven recommendations, these technologies help businesses make more informed decisions, reduce risks, and seize opportunities more effectively.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeSixFaq;