import React from 'react';
import { CgArrowLongLeft, CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

const PortfolioDetailsArea = () => {
   return (
      <>
         <section className="portfolio__details pt-120 pb-130">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                     <div className="portfolio__img-wrapper">
                        <div className="portfolio__img mb-30 w-img" >
                           <img src="assets/img/portfolio/details/port-01.jpg" alt="" />
                        </div>
                        <div className="portfolio__img mb-30 w-img" >
                           <img src="assets/img/portfolio/details/port-02.jpg" alt="" />
                        </div>
                        <div className="portfolio__img mb-30 w-img" >
                           <img src="assets/img/portfolio/details/port-03.jpg" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
                     <div className="portfolio__details-content mt-10">
                        <div className="portfolio__meta mb-5" >
                        </div>
                        <h1 >Branding</h1>
                        <div className="portfolio__info mb-25" >
                           <h3>Category:</h3>
                           <p> UX Design, Art Direction, Consulting, Web App Development </p>
                        </div>
                        <div className="portfolio__info mb-25" >
                           <h3>Customer: </h3>
                           <p>Stride with Confidence</p>
                        </div>
                        <div className="portfolio__overview mt-40" >
                           <h2>Project Overview</h2>
                           <p>Where Every Step Counts
                              To establish and enhance the brand identity through a comprehensive branding strategy that reflects the unique value propositions of our shoe line. The goal is to position the brand as a leader in both style and performance, appealing to our target market while fostering a strong emotional connection.
                           </p>
                           <p>
                              <h2>Brand Identity Development</h2>
                              <strong>Logo Design:</strong> Create a modern, memorable logo that captures the essence of the brand.
                              Color Palette: Develop a cohesive color scheme that resonates with our target audience and complements our product design.
                              Typography: Select fonts that reflect the brand’s personality and ensure readability across various platforms.
                              Visual and Messaging Strategy:<br />

                              <strong>Tagline and Messaging:</strong> Craft a compelling tagline and key messaging that communicate the brand’s core values and unique selling points.
                              Imagery and Graphics: Develop high-quality visuals that showcase the shoes in diverse scenarios, highlighting both style and functionality.
                              Brand Guidelines: Compile a comprehensive brand guidelines document to ensure consistency across all marketing materials and touchpoints.<br />

                              <strong>Website Design:</strong>  Design and develop an engaging, user-friendly website that highlights the shoe collection and facilitates easy online shopping.
                              Social Media Strategy: Create a social media plan to build brand awareness, engage with the community, and drive traffic to the website.
                              Advertising Campaigns: Develop digital and print advertising campaigns to reach our target audience effectively.<br />

                              <strong>Packaging Design:</strong> Design innovative and sustainable packaging that enhances the unboxing experience and reinforces brand identity.
                           </p>
                           <div className="about__list">
                              <ul>
                                 <h2>Key Deliverables</h2><br />
                                 <li ><span><i > <FaCheck /> </i>Logo and brand identity assets</span></li>
                                 <li ><span><i > <FaCheck /> </i>Tagline and messaging framework</span></li>
                                 <li ><span><i > <FaCheck /> </i>Visuals and graphics for marketing materials</span></li>
                                 <li ><span><i > <FaCheck /> </i>Website and social media content</span></li>
                                 <li ><span><i > <FaCheck /> </i>Advertising campaign assets</span></li>
                                 <li ><span><i > <FaCheck /> </i>Packaging design</span></li>
                              </ul>
                           </div>
                           <div className="about__list">
                              <ul>
                                 <h2>Success Metrics</h2><br />
                                 <li ><span><i > <FaCheck /> </i>Brand Awareness: Increase in brand recognition and recall.</span></li>
                                 <li ><span><i > <FaCheck /> </i>Engagement: Higher levels on social media and website.</span></li>
                                 <li ><span><i > <FaCheck /> </i>Sales Growth: Boost in sales and market share.</span></li>
                                 <li ><span><i > <FaCheck /> </i>Customer Feedback: Positive reviews and feedback.</span></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="portfolio__pagination-wrapper mt-70">
                  <div className="row">
                     <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="portfolio__pagination">
                           <Link to="/portfolioDetails" className="link-btn-2 link-btn-3">
                              Previous
                              <i > <CgArrowLongLeft /> </i>
                              <i > <CgArrowLongLeft /> </i>
                           </Link>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="portfolio__pagination text-end">
                           <Link to="/portfolioDetails" className="link-btn-2">
                              Next
                              <i > <CgArrowLongRight /> </i>
                              <i > <CgArrowLongRight /> </i>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default PortfolioDetailsArea;